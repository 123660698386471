import { useEffect, useState, SetStateAction } from "react";
import { toast } from "react-toastify";
import Modal from 'react-modal';
import { useParams, useHistory } from "react-router-dom";
import RestService from "../../services/RestService";
import "./OrdersPage.css";
import "react-datepicker/dist/react-datepicker.css";
import Box from '@material-ui/core/Box';
import Pagination from "../../components/CustomList/Pagination";


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: '30%',
        //marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    },
};

const LegalParentsPage = () => {

    const history = useHistory();
    let { id } = useParams<{ id: string }>();
    const [toggle, setToggle] = useState(1);
    const [installerData, setInstallerData] = useState<any>();
    const [edit, setEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [canShow, setCanShow] = useState(false);
    const [totalNoOfPages, setTotalNoOfPages] = useState(1);
    const [ordersList, setOrdersList] = useState<any>([]);
    const [page, setPage] = useState(1);
    const [groupID,setGroupID] = useState<any>("");
    const [selectedGrougID,setSelectedGroudID] = useState<any>();
    const [paymentGroupdetails,setpaymentGroupDetails] = useState<any>();

    const toggleTab = (index: SetStateAction<number>) => {
        setToggle(index);

        console.log('in RetailerTabs toggleTab index:', index);

    };


    const getInstallerData = () => {
        const successCallback = (response: any) => {

            setInstallerData(response);
            !!response.paymentGroup ? setSelectedGroudID(response.paymentGroup.id):setSelectedGroudID("");
            !!response.paymentGroup ? getPaymentGroupDetails(response.paymentGroup.id):getPaymentGroupDetails("");
        }
        const url = "/api/installer-service/installers/" + id;
        RestService.getRequest(url, successCallback, (err) =>
            console.log(err)
        );


    }

    const fetchOrderList = () => {
        const list : any = [];
        const url =
        "/api/installer-service/management/payment-groups?pageNo="+page+"&pageSize=10";
      const successCallback = (response: any) => { 
        response.data.map((item: any)=>{
            list.push({"companyName":item.companyName, "id":item.id});
        })     
        setOrdersList(list);
        setTotalNoOfPages(response.pageCount);
        setIsLoading(false);
      };
      RestService.getRequest(url, successCallback, (err) => console.log(err));
       
    };

    const changePage = (newPageNo: number) => {
        setPage(newPageNo);
      };

    const getPaymentGroupDetails = (id: any) =>{
        const url =
        "/api/installer-service/management/payment-groups/"+id;  
      const successCallback = (response: any) => {       
          setpaymentGroupDetails(response);
      };
      RestService.getRequest(url, successCallback, (err) => console.log(err));
    };

    useEffect(() =>{
        fetchOrderList();
    },[page])

    const onSaveData = () => {
        const data = {
            "paymentGroupId": groupID
          }
        const successCallback = () => {
            setCanShow(false);
            setEdit(false);
            getInstallerData();
            toast.success("New Paymnet Group has been linked.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: 'toast-error'
          });
          };
          const errorCallback = (err: any) => {
            toast.error("New Paymnet Group could not be linked.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: 'toast-error'
          });
        }
          const urlPut = "/api/installer-service/management/installers/"+id;
          RestService.putRequest(urlPut, data, successCallback, (err) => errorCallback(err)); 
    }

    const onClear = () => {
        if(!!edit){
            setEdit(false);
        } else {
            setCanShow(false);
        }

    }



    useEffect(() => {
        setIsLoading(true);
    }, []);



    useEffect(() => {
        if (!!isLoading) {
            getInstallerData();
        }

    }, [isLoading]);


    const handleChange = (value: any) => {
        setGroupID(value);
    };

    const handleSubmit = () => {
        setEdit(false);
    };

    const editLegalParent = () => {
        setCanShow(true);
    }


    return (
        <div className="OrderDetailsPage">
<br />
            <div className="box" >
                <h4 style={{ flexDirection: "row", marginBottom:"10px", marginTop: "0.10%", marginLeft: "1%", display:"flex" }}
                >
           <span style={{ marginLeft: "2%" }}>  Location Number  : <span className="groupDetails">{installerData?.atdLocationNumber}</span>
                    </span>


          Installer Id : <span className="groupDetails"> {installerData && JSON.stringify(installerData.id)}</span>

                    <span style={{ marginLeft: "2%" }}> </span>
          Installer Name :  <span className="groupDetails">{installerData && JSON.stringify(installerData.name)}</span>
          </h4><h4 style={{ flexDirection: "row", marginBottom:"10px", marginTop: "0.10%", marginLeft: "1%", display:"flex" }}
                >
          Payment Group Name :<span className="groupDetails"> {installerData &&  installerData.paymentGroup?.companyName}</span>
                    <span style={{ marginLeft: "2%" }}> </span>
                    Payment Group ID : <span className="groupDetails">{installerData && selectedGrougID}</span> 
                </h4>
            </div>
            <div style={{ marginLeft: "20px" }}>
                <div style={{ display: "flex", flexDirection: "row", width: "50%",alignItems: "center",borderBottom: "1px solid #dbdbdb" }}>
                    <p className="legalParentSection">Payment Group :<span > {paymentGroupdetails &&paymentGroupdetails.companyName}</span></p>
                    <button className="editButton" onClick={() => editLegalParent()} style={{ width: "230px", margin: "15px 0" }}>Edit Payment Group</button>
                </div>
                {(installerData?.paymentGroup != null) && paymentGroupdetails && <div>

                    <div style={{ display: "inline-block", width: "45%" }}>
                        <p>Company Name: <span className="groupDetails">{paymentGroupdetails.companyName }</span></p>
                        <p> Billing Email : <span className="groupDetails">{ paymentGroupdetails.billingContactEmail}</span></p>
                        <p> Payment Method : <span className="groupDetails">{ paymentGroupdetails.paymentMethod}</span></p>
                    </div>
                    <div style={{ display: "inline-block", width: "45%", verticalAlign: "top" }}>
                        <p>Contact Name: <span className="groupDetails">{paymentGroupdetails.billingContactName }</span></p>
                        <p>Installer Fees Xero Contact ID: <span className="groupDetails">{paymentGroupdetails.installerFeesXeroContactId }</span></p>
                    </div>                    
                </div>}
                

            </div>
            <Modal isOpen={canShow} style={customStyles} ariaHideApp={false}>
                <>
                    <h3>List of Payment Groups</h3>
                    <div className="legalContainer">
                    <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                            <input type="radio" onChange={(e)=>{handleChange("")}} value="none" name="legalParent" id="none" />
                            <label htmlFor="none">{"None"}</label>
                        </div>
                        {ordersList.map((item: any,index:any)=>{
                            return(
                        <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                            <input type="radio" onChange={(e)=>{handleChange(item.id)}} value={item.companyName} name="legalParent" id={item.companyName} />
                            <label htmlFor={item.companyName}>{item.companyName}</label>
                        </div>
                            )
                        })}
                        {totalNoOfPages >1 && <Pagination
                            currentPage={page}
                            totalNoPages={totalNoOfPages}
                            changePage={changePage}                        
                        />}
                        
                       
                        <div className="submitSection">
                            <button style={{ 'float': 'right', marginTop: "10px", marginRight: '10px', borderRadius: "5px" }} onClick={onSaveData}>Save</button>&nbsp;
                    <button style={{ 'float': 'right', marginTop: "10px", background: "#fff", color: "#0071BC", border: "1px solid #0071BC", borderRadius: "5px" }} onClick={onClear}>Cancel</button>&nbsp;

                    </div>
                    </div>
                </>
            </Modal>
           
        </div>
    );
};

export default LegalParentsPage;